import './App.css';

function App() {
  return (
    <div className="App">
	  Hello World!
    </div>
  );
}

export default App;
